import React, { useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "../Button/button";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const SideImage = (props) => {
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const linkToPage = props.download
    ? props.download
    : props.link
      ? props.link
      : props.category
        ? props.locale === "en"
          ? props.category === "projekty"
            ? "/en/projects/" + props.slug
            : props.category === "kariera"
              ? "/en/career/" + props.slug
              : "/en/" + props.category + "/" + props.slug
          : "/" + props.category + "/" + props.slug
        : props.locale === "sk"
          ? "/" + props.slug
          : "/" + props.locale + "/" + props.slug;

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      // controls.start("hidden")
    }
  }, [controls, inView]);
  return (
    <SectionWrapper
      className="section flex"
      id={props.id ? props.id : props.slug}
    >
      <div className="sectionImage">
        {props.illustr && (
          <GatsbyImage
            image={getImage(props.illustr.localFile.childImageSharp)}
            alt=""
            className="rounded shadow image"
          />
        )}
      </div>
      <div className="textBlock">
        {/* props.leadLine && (
          <motion.h3
            initial="hidden"
            ref={ref}
            animate={controls}
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
          >
            {props.leadLine}
          </motion.h3>
        )*/}
        {props.icon.localFile && !props.download && (
          <motion.img
            initial="hidden"
            ref={ref}
            animate={controls}
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8 }}
            className="logo"
            src={props.icon.localFile.publicURL}
            alt={props.title}
          />
        )}
        {props.title && (
          <motion.h1
            initial="hidden"
            ref={ref}
            animate={controls}
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
          >
            {props.title}
          </motion.h1>
        )}
        {(props.description || props.salary) && (
          <motion.p
            initial="hidden"
            ref={ref}
            animate={controls}
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1 }}
          >
            {props.salary
              ? props.place + " | " + props.kind + " | " + props.salary
              : props.description}
          </motion.p>
        )}

        {props.buttonText && (
          <a href={linkToPage} className="cta">
            <Button
              label={props.buttonText}
              cta={props.buttonText}
              colors={props.colors}
              icon={
                props.download && props.icon.localFile ? (
                  <img
                    src={props.icon.localFile.publicURL}
                    alt={props.buttonText}
                  />
                ) : null
              }
            />
          </a>
        )}
      </div>
    </SectionWrapper>
  );
};
const SectionWrapper = styled.div`
  align-items: center;
  .logo {
    height: 80px;
  }
  h1 {
    font-size: 40px;
    font-weight: bold;
    max-width: 430px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  p {
    max-width: 450px;
    margin-bottom: 2rem;
    font-size: 20px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  .textBlock {
    padding-left: 100px;
    @media (max-width: 991px) {
      margin-top: 90px;
      padding-left: 0px;
      text-align: center;
      max-width: 600px;
    }
  }
  .sectionImage {
    text-align: right;
    padding: 20px 0;
    flex: 1;
    .gatsby-image-wrapper {
      img {
        border-radius: 26px;
      }
    }
    .image {
      max-width: 560px;
      width: 100%;
      border-radius: 26px;

      @media (max-width: 991px) {
        max-width: 400px;
      }
    }

    @media (max-width: 991px) {
      margin-top: 4em;
      margin-bottom: 4em;
      .image {
        max-width: 450px;
      }
    }
  }

  @media (max-width: 991px) {
    flex-flow: column-reverse;
  }
`;

export default SideImage;
