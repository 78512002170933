import React, { useEffect, useRef } from "react";
import BackgroundImage from "gatsby-background-image";
import Button from "../Button/button";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import interpolate from "interpolate-range";

const BgImage = (props) => {
  const bgImage = convertToBgImage(
    getImage(props.illustr.localFile.childImageSharp)
  );
  const paralaxRef = useRef();
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const _locale = props.locale;

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      //controls.start("hidden")
    }
    window.addEventListener("scroll", scrollHandler);

    scrollHandler();
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [controls, inView]);
  const scrollHandler = () => {
    const offsetMin = paralaxRef.current.selfRef.offsetTop;
    const offsetMax =
      paralaxRef.current.selfRef.offsetTop +
      paralaxRef.current.selfRef.offsetHeight;

    const config = {
      inputRange: [
        offsetMin - paralaxRef.current.selfRef.offsetHeight * 0.5,
        offsetMax + paralaxRef.current.selfRef.offsetHeight * 0.5,
      ],
      outputRange: [0, paralaxRef.current.selfRef.offsetHeight * 2],
      clamp: true,
    };
    const inter = interpolate(config);

    if (
      window.pageYOffset + window.innerHeight >= offsetMin &&
      window.pageYOffset < offsetMax
    )
      paralaxRef.current.selfRef.style.perspectiveOrigin =
        "center " +
        (inter(window.pageYOffset) - paralaxRef.current.selfRef.offsetHeight) +
        "px";
  };

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
      id={props.id ? props.id : props.slug}
      className="full-width paralax"
      ref={paralaxRef}
    >
      <SectionWrapper className="section flex">
        <div className="textBlock">
          {props.leadLine && (
            <motion.h3
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants}
              transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
            >
              {props.leadLine}
            </motion.h3>
          )}
          {props.icon && !props.download && (
            <motion.img
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants}
              transition={{ ease: "easeOut", duration: 0.8 }}
              className="logo"
              src={props.icon.localFile.publicURL}
              alt={props.title}
            />
          )}
          {props.title && (
            <motion.h1
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants}
              transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
            >
              {props.title}
            </motion.h1>
          )}
          {(props.description || props.salary) && (
            <motion.p
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={variants}
              transition={{ ease: "easeOut", duration: 0.8, delay: 1 }}
            >
              {props.salary
                ? props.place + " | " + props.kind + " | " + props.salary
                : props.description}
            </motion.p>
          )}

          {props.buttonText && (
            <a
              href={
                props.download
                  ? props.download
                  : props.link
                  ? props.link
                  : props.category
                  ? _locale === "en"
                    ? props.category === "projekty"
                      ? "/en/projects/" + props.slug
                      : props.category === "kariera"
                      ? "/en/career/" + props.slug
                      : "/en/" + props.category + "/" + props.slug
                    : "/" + props.category + "/" + props.slug
                  : _locale === "sk"
                  ? "/" + props.slug
                  : "/" + _locale + "/" + props.slug
              }
              className="cta"
            >
              <Button
                label={props.buttonText}
                cta={props.buttonText}
                colors={props.colors}
                icon={
                  props.download && props.icon ? (
                    <img
                      src={props.icon.localFile.publicURL}
                      alt={props.buttonText}
                    />
                  ) : null
                }
              />
            </a>
          )}
        </div>
      </SectionWrapper>
    </BackgroundImage>
  );
};
const SectionWrapper = styled.div`
  align-items: center;
  @media (max-width: 991px) {
    justify-content: center;
    text-align: center;
  }
  h1 {
    font-size: 40px;
    font-weight: bold;
    max-width: 430px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  h3 {
    color: #262730;
  }
  p {
    max-width: 450px;
    margin-bottom: 2rem;
    font-size: 20px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  .sectionImage {
    text-align: right;
    flex: 1;
    .image {
      max-width: 560px;
      width: 100%;
      @media (max-width: 767px) {
        max-width: 400px;
      }
    }
  }

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export default BgImage;
