import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Card = (props) => {
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      //controls.start("hidden")
    }
  }, [controls, inView]);
  return (
    <SectionWrapper
      colors={props.colors}
      className={
        props.illustr ? "has-illustr card-" + props.flex : "card-" + props.flex
      }
      id={props.id ? props.id : props.slug}
      style={{
        backgroundColor: props.colors[0]["hex"],
        color: props.colors[1]["hex"],
        backgroundImage: props.illustr
          ? "url(" + props.illustr.localFile.publicURL + ")"
          : "url(" + props.icon.localFile.publicURL + ")",
        backgroundPosition: props.illustr ? "right top" : "right 10px top 10px",
      }}
      href={props.link ? props.link : null}
      name={props.slug}
      title={props.title}
    >
      <motion.h3
        initial="hidden"
        ref={ref}
        animate={controls}
        variants={variants}
        transition={{ ease: "easeOut", duration: 0.8, delay: 0.0 }}
        style={{
          color: props.colors[0]["hex"],
          backgroundColor: props.colors[1]["hex"],
        }}
      >
        {props.leadLine}
      </motion.h3>
      <motion.h2
        initial="hidden"
        ref={ref}
        animate={controls}
        variants={variants}
        transition={{ ease: "easeOut", duration: 0.8, delay: 0.3 }}
      >
        {props.title}
      </motion.h2>
      <motion.p
        initial="hidden"
        ref={ref}
        animate={controls}
        variants={variants}
        transition={{ ease: "easeOut", duration: 0.8, delay: 0.6 }}
      >
        {props.description}
      </motion.p>
    </SectionWrapper>
  );
};
const SectionWrapper = styled.a`
      min-height: 400px;
      border-radius: 24px;
      background-repeat: no-repeat;
      padding: 28px 40px;
      margin: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 30px;
      text-decoration: none;
      transition: transform .3s;
      &:hover{
        transform: scale(1.03);
      }
      &.card-1{
        max-width: 350px;
        flex: 1;
        background-size: 160px;
        @media(max-width: 991px){
          background-size: 40%;
        }
      }
      &.card-2{
        max-width: 730px;
        flex: 2;
        &.has-illustr p{
          max-width: 400px;
        }
        @media(max-width: 767px){
          &.has-illustr{
              background-size: 170px;
          }
        }
      }
      &.card-3{
        max-width: 1115px;
        flex: 3;
        @media(max-width: 991px){
          background-size: contain;
        }
      }
      h2 {
        font-size: 30px;
        margin-bottom: 0;
      }
      h3 {
        max-width: 80px;
        border-radius: 24px;
        padding: 2px;
        text-align: center;
        font-size: 20px;
        margin-bottom: 0;
      }
      p{
        margin-top: 5px;
        white-space: break-spaces;
      }
        @media(max-width: 991px){
          max-width: 100% !important;
        }
    }
`;

export default Card;
