import React from "react";
import styled from "styled-components";

const Html = (props) => {
  return (
    <SectionWrapper
      className="section html"
      id={props.id ? props.id : props.slug}
      dangerouslySetInnerHTML={{ __html: props.markup.html }}
    ></SectionWrapper>
  );
};
const SectionWrapper = styled.div``;

export default Html;
